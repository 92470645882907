
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MediaTeamSection',
  data() {
    return {
      sideClasses: 'team__item team__item_side',
      centerClass: 'team__item',
      containerClass: '',
      slides: [
        {
          id: 'left',
          classes: 'team__item',
          img: 'images/media-buying/1.jpg',
          link: 'https://t.me/Recruiter_MasonsTraffic',
          label: 't.me/Recruiter_MasonsTraffic',
        },
        {
          id: 'center',
          classes: 'team__item',
          img: 'images/media-buying/2.jpg',
          link: 'https://t.me/klim_pride',
          label: 't.me/klim_pride',
        },
        {
          id: 'right',
          classes: 'team__item',
          img: 'images/media-buying/3.jpg',
          link: 'https://t.me/alse_1337',
          label: 't.me/alse_1337',
        },
      ],
    }
  },
  methods: {},
})
